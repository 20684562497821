import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout pageTitle="404: Not found">
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>The page you tried to access does not exist.</p>
  </Layout>
)

export default NotFoundPage
